import { render, staticRenderFns } from "./TempLogin.vue?vue&type=template&id=8879d452&scoped=true"
import script from "./TempLogin.vue?vue&type=script&lang=js"
export * from "./TempLogin.vue?vue&type=script&lang=js"
import style0 from "./TempLogin.vue?vue&type=style&index=0&id=8879d452&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8879d452",
  null
  
)

export default component.exports