import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login'
import TempLogin from '../view/TempLogin'
import Register from '../view/Register'
import Home from '../view/Home'
import TempHome from '../view/TempHome'
// 安装路由
Vue.use(VueRouter);

// 配置导出路由
export default new VueRouter({
  routes: [{
      path: "/", 
      redirect: "/login" 
    },
    {
      name: "Login",
      path: '/login',
      component: Login
    },
	{
		name: "TempLogin",
		path: '/templogin',
		component: TempLogin
	  },
    {
      name: "Register",
      path: '/register',
      component: Register
    },
    {
      name: "Home",
      path: '/home',
      component: Home,
	  children:[
		  {
		    name: "Chat",
		    path: "/home/chat",
		    component: () => import("../view/Chat"),
		  },
		{
		  name: "Friends",
		  path: "/home/friend",
		  component: () => import("../view/Friend"),
		},
		{
		  name: "Friends",
		  path: "/home/group",
		  component: () => import("../view/Group"),
		}
	  ]
    },
    {
      name: "TempHome",
      path: '/temphome',
      component: TempHome,
	  children:[
		  {
		    name: "TempChat",
		    path: "/temphome/tempchat",
		    component: () => import("../view/TempChat"),
		  },
		{
		  name: "Friends",
		  path: "/temphome/friend",
		  component: () => import("../view/Friend"),
		},
		{
		  name: "Friends",
		  path: "/temphome/group",
		  component: () => import("../view/Group"),
		}
	  ]
    }
  ]

});
